@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/inter.ttf');
}

@font-face {
  font-family: 'GTAmerica';
  src: url('./assets/fonts/GTAmerica.ttf');
}

@font-face {
  font-family: 'linear-sans';
  src: url('./assets/fonts/linearsans-regular.otf');
}

@font-face {
  font-family: 'sohne';
  src: url('./assets/fonts/sohne/sohne-leicht.otf');
}

@font-face {
  font-family: 'sohne';
  src: url('./assets/fonts/sohne/sohne-kraftig.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'sohne-breit';
  src: url('./assets/fonts/sohne/sohnebreit-kraftig.otf');
}

@font-face {
  font-family: 'sohne-breit';
  src: url('./assets/fonts/sohne/sohnebreit-dreiviertelfett.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'sohne-breit';
  src: url('./assets/fonts/sohne/sohnebreit-extrafett.otf');
  font-weight: 700;
}

@layer base {
  input,
  select,
  textarea {
    @apply !ring-0;
  }
}

#root {
  height: 100%;
}

input[type='radio'] {
  accent-color: #4228b2;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 0.1rem;
  height: 0.1rem;
}

.custom-scrollbar::-webkit-scrollbar-track {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  --bg-opacity: 1;
  background-color: #edf2f7;
  background-color: rgba(237, 242, 247, var(--bg-opacity));
  border-radius: 0.25rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.custom-scrollbar-1::-webkit-scrollbar {
  width: 10px;
}

.custom-scrollbar-1::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #9b9b9b;
}

@layer base {
  input[type='radio'] {
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='8' viewBox='0 0 11 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.25 3.65789L4.33333 6.5L9.25 1.5' stroke='%23fff' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E") !important;
    background-size: unset !important;
    border-color: transparent;
    background-position: center;
    background-repeat: no-repeat;
  }

  * {
    overflow-wrap: break-word;
  }
}

@layer utilities {
  .highlight-marker {
    @apply relative z-10;
  }

  .highlight-marker:after {
    @apply absolute bottom-0 top-0 my-auto -left-2 mx-auto h-[20px] bg-yellow-500 rounded-lg;
    content: '';
    z-index: -1;
    width: calc(100% + 1rem);
  }
}
